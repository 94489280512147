
<template>
  <section class="container-fluid">
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="box">
          <form v-form-validate="{ submitMethod: onSubmit, locale: locale }">
            <h2 class="title is-2 has-text-centered">{{ $t('UploadData.Title') }}</h2>
            <div class="field material-input">
              <div class="control">
                <input class="input" v-model="formData.name" type="text" placeholder="">
                <label class="label">{{ $t('UploadData.ProjectName') }}</label>
              </div>
            </div>

            <div class="field material-select">
              <select v-model="formData.projectType" class="select" required>
                <option value=""></option>
                <option value="Office Building" placeholder="">{{ $t('UploadData.ProjectTypes.OfficeBuilding') }}</option>
                <option value="Single-Family Residential" placeholder="">{{ $t('UploadData.ProjectTypes.SingleFamilyResidential') }}</option>
                <option value="Multi-Family Residential">{{ $t('UploadData.ProjectTypes.MultiFamilyResidential') }}</option>
                <option value="Industrial Facility">{{ $t('UploadData.ProjectTypes.IndustrialFacility') }}</option>
                <option value="Hotel">{{ $t('UploadData.ProjectTypes.Hotel') }}</option>
                <option value="Retail">{{ $t('UploadData.ProjectTypes.Retail') }}</option>
                <option value="Hospital">{{ $t('UploadData.ProjectTypes.Hospital') }}</option>
                <option value="Stadium">{{ $t('UploadData.ProjectTypes.Stadium') }}</option>
                <option value="Street">{{ $t('UploadData.ProjectTypes.Street') }}</option>
                <option value="Railway">{{ $t('UploadData.ProjectTypes.Railway') }}</option>
                <option value="MEP">{{ $t('UploadData.ProjectTypes.MEP') }}</option>
                <option value="other">{{ $t('UploadData.ProjectTypes.Other') }}</option>
              </select>
              <label class="label">{{ $t('UploadData.ProjectType') }}</label>
            </div>

            <div class="field material-input">
              <div class="control">
                <input class="input" v-model="formData.address" type="text" placeholder="">
                <label class="label">{{ $t('UploadData.ProjectAddress') }}</label>
              </div>
            </div>

            <div class="field material-select" required>
              <div class="control">
                <select v-model="formData.yearOfConstruction" class="select has-value" required>
                  <option value=""></option>
                  <option value="< 1800">&lt; 1800</option>
                  <option value="1800-1900">1800-1900</option>
                  <option value="1900-1940">1900-1940</option>
                  <option value="1940-1960">1940-1960</option>
                  <option value="1960-1980">1960-1980</option>
                  <option value="1980-2000">1980-2000</option>
                  <option value="2000-2020">2000-2020</option>
                  <option value="> 2020">&gt; 2020</option>
                </select>
                <label class="label">{{ $t('UploadData.YearOfConstruction') }}</label>
              </div>
            </div>

            <div class="field material-select">
              <div class="control">
                <select class="select" v-model="formData.levelOfGeometry" required>
                  <option value=""></option>
                  <option value="LOG 100">LOG 100</option>
                  <option value="LOG 200">LOG 200</option>
                  <option value="LOG 300">LOG 300</option>
                  <option value="LOG 400">LOG 400</option>
                  <option value="LOG 500">LOG 500</option>
                </select>
                <label class="label">{{ $t('UploadData.LevelOfGeometry') }}</label>
              </div>
            </div>

            <!-- <div class="field material-input">
              <div class="control">
                <input class="input" v-model="formData.constructionType" type="text" placeholder="">
                <label class="label">Construction-Type</label>
              </div>
            </div> -->

            <!-- <div class="field">
            <label class="label">Library Elements</label>
            <div class="control">
              <input class="input" type="text" placeholder="Library Elements">
              
              <select class="select">
                <option value="NYNE Template"></option>
                <option value="Custom"></option>
              </select>
            </div>
          </div> -->

            <div class="field is-grouped is-justify-content-center">
              <div class="control">
                <button class="button is-primary">{{ $t('General.Next') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- <div class="columns is-centered">
      <div class="column has-text-centered">
        <p class="is-size-2">
          {{ uploadedSize }} {{ uploadedSizeUnit }} / {{ fileSize }} {{ fileSizeUnit }} ({{ uploadProgress }}%)
        </p>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column is-two-thirds">
        <progress class="progress is-primary is-small" style="width:100%;margin-bottom:0.5rem;" :value="uploadProgress"
          max="100">{{
            uploadProgress
          }}%</progress>
        <p class="has-text-centered">
          Please don't close the window until upload is finished!
        </p>
      </div>
    </div> -->
  </section>
</template>

<script setup lang="ts">

import { ref, watch } from 'vue'
import { useStore } from '@/store';
import { UploadView } from '@/models/upload.model';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
// const fileSize = computed(() => store.state.upload.fileSize);
// const fileSizeUnit = computed(() => store.state.upload.fileSizeUnit);
// const uploadedSize = computed(() => store.state.upload.uploadedSize);
// const uploadedSizeUnit = computed(() => store.state.upload.uploadedSizeUnit);
// const uploadProgress = computed(() => store.state.upload.uploadProgress);
store.state.app.disableLoadingSpinner = false;

const idFromUrl = ref('');
const idParam = route.params.id;
// Check if the parameter is an array and handle accordingly
if (Array.isArray(idParam)) {
  idFromUrl.value = idParam[0]; // or some other logic to choose the correct value
} else {
  idFromUrl.value = idParam;
}

let formData: UploadView = {
  id: idFromUrl.value,
  name: '',
  projectType: '',
  address: '',
  yearOfConstruction: '',
  levelOfGeometry: '',
  constructionType: '',
  description: '',
  status: '',
};

watch(() => route.params.id, (newValue) => {
  if (Array.isArray(newValue)) {
    idFromUrl.value = newValue[0];
  } else {
    idFromUrl.value = newValue;
  }
  // todo try to load values from database?
  formData = {
    id: idFromUrl.value,
    name: '',
    projectType: '',
    address: '',
    yearOfConstruction: '',
    levelOfGeometry: '',
    constructionType: '',
    description: '',
    status: '',
  };
});

const onSubmit = async () => {
  await store.dispatch('upload/updateUploadData', { data: formData });
  
  if (store.state.auth.isAuthenticated) {
    router.push({ name: 'Projects' });
  } else {
    router.push({ name: 'Login' });
  }
};

</script>

<style scoped></style>
