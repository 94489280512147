<template>
    <section class="hero is-primary is-halfheight">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">Willkommen beim Nyne Cloud Upload</h1>
                <p class="subtitle">
                    Willkommen auf unserer Internetseite! Wir sind dankbar, dass Sie sich für Gebäudemanagement interessieren. Unser Ziel ist
                    es, Ihnen möglichst einfach ihr Gebäude zu digitalisieren.
                </p>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
</script>