<template>
    <section class="section">
        <div class="box">
            <h1 class="title">{{ $t('RegisterConfirm.Title') }}</h1>
            <p v-if="!hasError">{{ $t('RegisterConfirm.Success') }}</p>
            <Login v-if="!hasError"></Login>
            <p v-else>{{ $t('RegisterConfirm.Error') }}</p>
        </div>
    </section>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '../store';
import Login from '@/components/Login.vue'; // @ is an alias to /src

const route = useRoute();
const store = useStore();

const email = ref('');
const token = ref('');
const hasError = ref(false);

async function verifyRegistration() {
    try {
        await store.dispatch('auth/confirmRegistration', { email: email.value, token: token.value });
    } catch (error) {
        hasError.value = true;
        console.error('Registration verification failed:', error);
    }
}

onMounted(() => {
    const queryEmail = route.query.email as string;
    const queryToken = route.query.token as string;

    if (queryEmail && queryToken) {
        email.value = queryEmail;
        token.value = queryToken;
        verifyRegistration();
    }
});
</script>