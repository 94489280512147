<template>
    <section class="section">
        <div class="box">
            <h1 class="title is-2 has-text-centered">{{ $t('AccessDenied.Subtitle') }}</h1>
            <h2 v-if="reason" class="subtitle is-5 has-text-centered mt-4"><b>{{ $t('AccessDenied.Error') }}: </b> {{ reason }}</h2>
            <h2 v-if="!reason" class="subtitle is-5 has-text-centered mt-4">{{ $t('AccessDenied.Text') }}</h2>

            <div v-if="isReturnUrlValid" class="field has-text-centered">
                <button type="button" @click="goBack" class="button is-primary">{{ $t('General.Back') }}</button>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">

import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const reason = router.currentRoute.value.query.reason;
const returnUrl = router.currentRoute.value.query.returnUrl;

const decodedReturnUrl = ref<string>('');
const isReturnUrlValid = computed(() => decodedReturnUrl.value && decodedReturnUrl.value.length > 0 && !decodedReturnUrl.value.includes("http") && !decodedReturnUrl.value.includes("://"));

if (typeof returnUrl === 'string') {
    decodedReturnUrl.value = decodeURIComponent(returnUrl);
} else if (typeof returnUrl === 'undefined') {
    console.log("undefined?", returnUrl);
} else {
    decodedReturnUrl.value = decodeURIComponent(returnUrl![0] ?? "");
}

const goBack = async () => {
    if (isReturnUrlValid.value !== true) {
        return;
    }
    await router.push(decodedReturnUrl.value);
}
</script>