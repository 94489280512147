
<template>
  <section class="container-fluid">
    <div class="columns is-centered is-gapless">
      <div class="column is-half">
        <template v-if="!acceptedDataPrivacy && !isAuth">
          <div class="box">
            <div class="has-text-centered">
              <h1 class="title is-2">{{ $t('Upload.AcceptTerms.Title') }}</h1>
              <p v-html="$t('Upload.AcceptTerms.Text')"></p>
              <div class="field mt-4">
                <div class="control">
                  <button class="button is-primary" @click="store.commit('app/acceptDataPrivacy')">
                    {{ $t('Upload.AcceptTerms.Submit') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="!fileId" class="drop-zone-wrapper">
            <div class="drop-zone card" @click="triggerFileInput" @dragover.prevent="onDragOver" @drop.prevent="onDrop">
              <div class="card-content has-text-centered">
                <h1 class="title is-2">{{ $t('Upload.Title') }}</h1>
                <p class="subtitle is-4">{{ $t('Upload.Subtitle') }}</p>
                <div class="upload-icon">
                  <i class="nyne-icon nyne-icon-upload"></i>
                  <!-- <font-awesome-icon :icon="'cloud-upload'" class="fa-4x mt-2"></font-awesome-icon> -->
                </div>
                <input type="file" ref="fileInput" @change="onChange" class="is-hidden" />
              </div>
            </div>
          </div>
          <div v-if="fileId">
            <h1 class="title is-2 has-text-centered">{{ $t('Upload.Uploading') }}</h1>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">

import { ref, computed } from 'vue'
import { store } from '@/store';

import { useRouter } from 'vue-router';

const endpoint = '/files'
const fileInput = ref<any>(store.state.upload.currentFile);
  const acceptedDataPrivacy = computed(() => store.state.app.acceptedDataPrivacy);
  const isAuth = computed(() => store.state.auth.isAuthenticated);

const fileName = ref('');
const fileId = computed<string>(() => store.state.upload.fileId);

// Drag over event handler
const onDragOver = () => {
  // Optional: Add some visual feedback
};

const triggerFileInput = () => {
  if (fileInput.value) {
    fileInput.value.click(); // Trigger the hidden file input
  }
};

const router = useRouter();

const processFile = async () => {
  const file = fileInput.value;
  store.state.app.disableLoadingSpinner = true;
  const id = await store.dispatch('upload/prepareUploadStart');
  await store.dispatch('upload/startUpload', { file, endpoint, id });
  await router.push({ name: 'UploadData', params: { id } });
}

// Drop event handler
const onDrop = (event: any) => {
  const files = event.dataTransfer.files;
  if (files.length === 0) {
    return;
  }

  fileInput.value = files[0];
  fileName.value = fileInput.value.name;

  processFile();

};

const onChange = (e: any) => {
  // Get the selected file from the input element
  // const file: any = { value: undefined };
  const target = e.target as HTMLInputElement;
  if (target.files && target.files.length > 0) {
    // console.log("SET FILE", target.files);
    fileInput.value = target.files[0];
    fileName.value = fileInput.value.name;
    processFile();
  }

};
</script>

<style scoped>
.drop-zone {
  border: 2px dashed #ccc;
  padding: 40px 20px;
  border-radius: 10px;
  transition: border-color 0.25s ease-in-out;
  /* outline: 30px solid white; */
  height: 50vh;
  max-height: 400px;
  padding-top: 9vh;
}

.drop-zone-wrapper {
  /* margin: 20px; */
  margin: auto;
  border: 30px solid white;
  border-radius: 25px;
  width: 80%;
  max-width: 750px;
  min-width: 500px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

  @media screen and (max-width: 768px) {
    width: 98%;
    max-width: unset;
    min-width: unset;
  }
}

.drop-zone:hover {
  border-color: #3273dc;
  /* Primary color in Bulma */
}

.upload-icon .nyne-icon {
  font-size: 6rem;
}
</style>
```
