<template>
    <div class="container-fluid">
        <section class="section">
            <h1 class="title is-2 has-text-centered">{{ $t('Accounts.Title') }}</h1>
            <div class="box full-width">
                <AccountsExcelExportButton class="is-pulled-right mb-3" :users="accounts"></AccountsExcelExportButton>
                <SortableTable :columns="columns" :rows="accounts"></SortableTable>
            </div>
        </section>
    </div>
</template>
<script setup lang="ts">
import { TableColumn } from '@/models';
import { computed, onMounted } from 'vue';
import { useStore } from '../store';
import SortableTable from './SortableTable.vue';
import AccountsExcelExportButton from './AccountsExcelExportButton.vue';
import { useI18n } from 'vue-i18n';
import { formatDate } from '@/utils/formatBytes';

const { t } = useI18n();
const store = useStore();
const accounts = computed(() => store.state.account.accounts);

const columns: TableColumn[] = [
    {
        field: 'id',
        title: '',
        sortable: false,
        actions: {
            lock: async (account: any) => {
                account.active = false;
                await store.dispatch('account/updateAccountLocked', { id: account.id, account });
                store.commit('app/showToast', {
                    message: t('Accounts.Lock'),
                    type: 'success',
                });
            },
            unlock: async (account: any) => {
                account.active = true;
                await store.dispatch('account/updateAccountLocked', { id: account.id, account });
                store.commit('app/showToast', {
                    message: t('Accounts.Unlock'),
                    type: 'success',
                });
            },
            addAdmin: async (account: any) => {
                if (!account.roles) {
                    account.roles = ["User"];
                }
                if (!account.roles.includes("Administrator")) {
                    account.roles.push("Administrator");
                }
                await store.dispatch('account/updateAccountRoles', { id: account.id, account });
                store.commit('app/showToast', {
                    message: t('Accounts.SetAdmin'),
                    type: 'success',
                });
            },
            removeAdmin: async (account: any) => {
                if (!account.roles) {
                    // nothing to do
                    return;
                }
                if (account.roles.includes("Administrator")) {
                    account.roles.splice("Administrator", 1);
                }
                await store.dispatch('account/updateAccountRoles', { id: account.id, account });
                store.commit('app/showToast', {
                    message: t('Accounts.UnsetAdmin'),
                    type: 'success',
                });
            },
            delete: async (account: any) => {
                await store.dispatch('account/deleteAccount', account.id);
                store.commit('app/showToast', {
                    message: t('Accounts.Delete'),
                    type: 'success',
                });
            },
        },
    },
    {
        field: 'name', title: 'General.Name', sortable: true,
        fieldReference: (row) => {
            return row.lastName + " " + row.firstName;
        },
    },
    { field: 'email', title: 'General.Email', sortable: true },
    {
        field: 'countProjects',
        fieldReference: (row) => {
            return + row.countFinishedProjects + " / " + row.countProjects;
        },
        title: '#', sortable: true
    },
    {
        field: 'projectIds', title: 'Accounts.Columns.CountProjects', sortable: false,
        fieldReference: (row) => {
            // add link for each project, referencing to projectDetail (/project/id)
            const fullList = [];
            if (row.projects && row.projects.length > 0) {
                for (let i = 0; i < row.projects.length; i++) {
                    fullList.push('<a title="' + row.projects[i].description + '" target="_blank" href="/project/' + row.projects[i].id + '">' + row.projects[i].name + '</a>');
                    if (i < row.projects.length - 1) {
                        fullList[i] += ', ';
                    }
                }
                return fullList.join('<br>');
            }
            return "";
        },
    },
    {
        field: 'roles',
        title: 'Accounts.Columns.Roles',
        sortable: false,
        fieldReference: (row) => {
            return row.roles.join(', ');
        },
    },
    {
        field: 'dateCreated',
        fieldType: 'date',
        title: 'Accounts.Columns.AccountCreated',
        sortable: true,
        fieldReference: (row) => {
            return "reg:" + formatDate(row.dateCreated, 'dd.MM.yyyy HH:mm') + ', act:' + formatDate(row.dateActivated, 'dd.MM.yyyy HH:mm');
        },
    },
    // {
    //     field: 'dateActivated',
    //     fieldType: 'date',
    //     title: 'Accounts.Columns.AccountActivated',
    //     sortable: true,
    // },
    {
        field: 'dateLastLogin',
        fieldType: 'date',
        title: 'Accounts.Columns.LastLogin',
        sortable: true,
    },
    // {
    //     field: 'dateDeleted',
    //     fieldType: 'date',
    //     title: 'Accounts.Columns.AccountDeleted',
    //     sortable: true,
    // },
];

onMounted(async () => {
    await store.dispatch('account/getAccounts');
});
</script>
