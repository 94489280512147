<template>
    <div class="container-fluid">
        <section class="section">
            <h1 class="title is-2 has-text-centered">{{ $t('Statistics.Title') }}</h1>
            <div class="box full-width">
                <SortableTable :columns="columns" :rows="statistics"></SortableTable>
            </div>
        </section>
        <section class="section">
            <h1 class="title is-2 has-text-centered">Processing Time</h1>
            <div class="box full-width">
                <SortableTable :columns="processingTimeColumns" :rows="processingTime"></SortableTable>
            </div>
        </section>
        <section class="section">
            <h1 class="title is-2 has-text-centered">Waiting Time</h1>
            <div class="box full-width">
                <SortableTable :columns="waitingTimeColumns" :rows="waitingTime"></SortableTable>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import { TableColumn } from '@/models';
import { computed, onMounted } from 'vue';
import { useStore } from '../store';
import SortableTable from './SortableTable.vue';
// import { useI18n } from 'vue-i18n';

// const { t } = useI18n();
const store = useStore();
const statistics = computed(() => store.state.statistics.statistics);
const processingTime = computed(() => [store.state.statistics.processingTime]);
const waitingTime = computed(() => store.state.statistics.waitingTime);

const columns: TableColumn[] = [
    // { field: 'projectId', title: 'Statistics.Columns.ProjectId', sortable: true },
    // { field: 'projectFileId', title: 'Statistics.Columns.ProjectFileId', sortable: true },
    // { field: 'averageDuration', title: 'Statistics.Columns.AverageDuration', sortable: true },
    // { field: 'pointCloudsProcessed', title: 'Statistics.Columns.PointCloudsProcessed', sortable: true },
    // { field: 'processType', title: 'Statistics.Columns.ProcessType', sortable: true },
    { field: 'processedProjects', title: 'Statistics.Columns.ProcessedProjects', sortable: true },
    { field: 'processedFilesOnAverage', title: 'Statistics.Columns.ProcessedFilesOnAverage', sortable: true },
    { field: 'processedTotalFiles', title: 'Statistics.Columns.ProcessedTotalFiles', sortable: true },
    { field: 'status', title: 'Statistics.Columns.Status', sortable: true },
    { field: 'averageDuration', title: 'Statistics.Columns.AverageDuration', sortable: true },
    { field: 'averageFileSize', title: 'Statistics.Columns.AverageFileSize', sortable: true,
        fieldReference: (row) => row.averageFileSize ? row.averageFileSize + ' MB' : ''
    }
];

const processingTimeColumns: TableColumn[] = [
    { field: 'totalProjects', title: 'Statistics.Columns.TotalProjects', sortable: true, 
        fieldReference: (row) => row.finishedProjects + " / " + row.totalProjects
    },
    { field: 'averageFileSize', title: 'Statistics.Columns.AverageFileSize', sortable: true,
        fieldReference: (row) => row.averageFileSize ? row.averageFileSize + ' MB' : '0 MB'
    },
    { field: 'totalFileSize', title: 'Statistics.Columns.TotalFileSize', sortable: true,
        fieldReference: (row) => row.totalFileSize ? row.totalFileSize + ' MB' : '0 MB'
    },
    { field: 'averageProcessingDuration', title: 'finished', sortable: true },
    { field: 'averageProcessingDurationUnfinished', title: 'unfinished', sortable: true }
];

const waitingTimeColumns: TableColumn[] = [
    { field: 'status', title: 'Statistics.Columns.Status', sortable: true },
    { field: 'totalWaitingTime', title: 'Statistics.Columns.TotalWaitingTime', sortable: true },
    { field: 'totalProjects', title: 'Statistics.Columns.TotalProjects', sortable: true },
    { field: 'averageFileSize', title: 'Statistics.Columns.AverageFileSize', sortable: true,
        fieldReference: (row) => row.averageFileSize ? row.averageFileSize + ' MB' : ''
    }
]

onMounted(async () => {
    const minDate = new Date('2023-01-01'); // Example min date
    const maxDate = new Date(); // Current date as max date
    await store.dispatch('statistics/getUsage', { minDate, maxDate });
    await store.dispatch('statistics/getProcessingTime', { minDate, maxDate });
    await store.dispatch('statistics/getWaitingTime', { minDate, maxDate });
});
</script>
