<template>
    <section class="section">
        <div class="box form-box">
            <h1 class="title is-1 has-text-centered pb-4">{{ $t('Register.Title') }}</h1>
            <h2 class="subtitle small is-5 has-text-centered">{{ $t('General.Or') }} <router-link to="/login">{{ $t('Login.Title') }}</router-link></h2>

            <div class="tab-content">
                <div class="login-form email">
                    <form v-form-validate="{ submitMethod: register, locale: locale }" ref="form" novalidate>
                        <div class="field material-input">
                            <div class="control has-icons-left">
                                <input placeholder="" class="input" type="email" id="email" v-model="email"
                                    required />
                                <label class="label" for="email">{{ $t('General.Email') }}</label>
                                <!-- <span class="icon is-small is-left">
                                    <font-awesome-icon icon="envelope" />
                                </span> -->
                            </div>
                        </div>
                        <div class="columns" v-if="false">
                            <!-- <div class="column">
                                <div class="field material-input">
                                    <div class="control has-icons-left">
                                        <input v-validate placeholder="" class="input" type="firstName" id="firstName"
                                            v-model="firstname" required />
                                        <label class="label" for="firstName">Vorname</label>
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon icon="user" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="column">
                                <div class="field material-input">
                                    <div class="control has-icons-left">
                                        <input v-validate placeholder="" class="input" type="lastName" id="lastName"
                                            v-model="lastname" required />
                                        <label class="label" for="lastName">Nachname</label>
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon icon="user" />
                                        </span>
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <div class="columns">
                            <div class="column">
                                <div class="field material-input">
                                    <div class="control has-icons-left">
                                        <input placeholder="" class="input" type="password" id="password"
                                            v-model="password" required />
                                        <label class="label" for="password">{{ $t('General.SetPassword') }}</label>
                                        <!-- <span class="icon is-small is-left">
                                            <font-awesome-icon icon="lock" />
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                            <div class="column" v-if="false">
                                <div class="field material-input">
                                    <div class="control has-icons-left">
                                        <!-- <input v-validate placeholder="" class="input" type="password" id="passwordRepeat"
                                            v-model="passwordRepeat" required />
                                        <label class="label" for="password">Repeat password</label>
                                        <span class="icon is-small is-left">
                                            <font-awesome-icon icon="lock" />
                                        </span>
                                        <span class="help is-danger"
                                            v-if="passwordRepeat !== password && passwordRepeat.length > 0 && password.length > 0">
                                            Passwords do not match
                                        </span> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="field">
                            <label class="label checkbox-label">
                                <input type="checkbox" v-model="hasNewsletter" />
                                <span>
                                    {{ $t('General.Newsletter') }}
                                </span>
                            </label>
                            <label class="label checkbox-label">
                                <input type="checkbox" required />
                                <span v-html="$t('Register.Consent.Text')">
                                </span>
                            </label>
                            <div class="has-text-centered">
                                <button type="submit" class="button is-primary">{{ $t('Register.Submit') }}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/store';
// import { isValidPassword } from '@/directives/validationDirective';

import { useI18n } from 'vue-i18n';

const { locale } = useI18n();
const router = useRouter();
const store = useStore();
// const activeTab = ref('email');
const email = ref('');
const firstname = ref('');
const lastname = ref('');
const password = ref('');
// const passwordRepeat = ref('');
const hasNewsletter = ref(false);
const form = ref(null);

async function register() {
    try {
        await store.dispatch('auth/register', {
            email: email.value,
            password: password.value,
            firstName: firstname.value,
            lastName: lastname.value,
            hasNewsletter: hasNewsletter.value,
        });
        if (store.state.upload.fileId && store.state.upload.fileId !== '') {
            await store.dispatch('upload/assignUser', { id: store.state.upload.fileId });
        }
        router.push({ name: 'Projects' });
    } catch (error) {
        // Fehlerbehandlung, z.B. Anzeige einer Fehlermeldung
        console.error('Registration failed:', error);
    }
}
</script>