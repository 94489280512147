<template>
    <section class="section">
        <div class="container box">
            <div class="columns">
                <div class="column">
                    <h1 class="title">Liste der Teams</h1>
                </div>
                <div class="column is-narrow">
                    <button type="button" class="button is-primary" @click="goToTeamDetail">
                        <font-awesome-icon :icon="'add'" class="mr-2"></font-awesome-icon>
                        Neuer Upload
                    </button>
                </div>
            </div>
            <!-- <SortableTable :rows="calculations" :columns="columns" /> -->
        </div>
    </section>
</template>

<script setup lang="ts">
// import { TableColumn } from '@/models';
// import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
// import { useStore } from '../store';
// import SortableTable from './SortableTable.vue';

// const store = useStore();

// const openConfirmDialog = (calculation: any) => {
//   const title = 'Upload löschen';
//   const text = `Möchten Sie die ausgewählte Upload wirklich löschen? Diese kann nicht mehr wiederhergestellt werden.`;
//   const action = async () => {
//     await store.dispatch('calculation/deleteCalculation', calculation.id);
               
//     store.commit('app/showToast', {
//       message: 'Die Upload wurde erfolgreich gelöscht.',
//       type: 'success',
//     });
//   };

//   store.commit('app/showConfirmDialog', { title, text, action });
// };

// const columns: TableColumn[] = [
//     {
//         field: 'id',
//         title: '',
//         sortable: false,
//         actions: {
//             edit: async (calculation: any) => {
//                 await router.push({ name: 'CalculationDetail', params: { id: calculation.id } });
//             },
//             delete: (calculation: any) => {
//                 openConfirmDialog(calculation);
//             },
//         },
//     },
//     { field: 'name', title: 'Bezeichnung', sortable: true },
//     { field: 'productionNumber', title: 'Geschätzte Größe', sortable: true },
//     {
//         field: 'dateCreated',
//         fieldType: 'date',
//         title: 'Erstellt am',
//         sortable: true,
//     },
// ];

const router = useRouter();

function goToTeamDetail() {
    router.push({ name: 'Home' });
}

// onMounted(async () => {
//     await store.dispatch('calculation/getCalculations');
// });
</script>
