<template>
    <div class="is-overlay">
        <div class="toast-container">
            <transition-group name="fade">
                <div v-for="(toast, index) in toasts" :key="index" class="notification" :class="`is-${toast.type}`">
                    {{ toast.message }}
                    <button type="button" class="button delete" @click="dismissToast(index)">
                        <span class="icon">
                            <font-awesome-icon :icon="['fas', 'times']"></font-awesome-icon>
                        </span>
                    </button>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useStore } from '../store';
import { Toast } from '@/models';

const store = useStore();
const toasts = computed<Toast[]>(() => store.state.app.toasts ?? []);

watch(
    toasts,
    () => {
        toasts.value.forEach((toast: Toast, index: number) => {
            setTimeout(() => {
                dismissToast(index);
            }, 10000);
        });
    },
    { deep: true },
);

const dismissToast = (index: number) => {
    store.commit('app/dismissToast', index);
};
</script>

<style scoped>
.fade-enter-active {
    animation: toast-fade-in 0.4s ease;
}

.fade-leave-active {
    animation: toast-fade-out 0.4s ease;
}

@keyframes toast-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes toast-fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.is-overlay {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    z-index: 1000;
    pointer-events: none;
}

.button.delete {
    border: 0;
    background: none;
    float: right;
    top: 0;
    right: 0;
    color: white;
}
.toast-container {
    pointer-events: all;
    position: fixed;
    top: 1.5rem;
    width: 100%;
    padding: 0 10px;
}
@media (min-width: 768px) {
    .toast-container {
        width: 35vw;
        min-width: 300px;
        right: 1rem;
        padding: 0;
    }
}
</style>
