<template>
  <button type="button" class="button is-primary" @click="generateExcel">
    <font-awesome-icon :icon="'file-excel'" class="mr-2"></font-awesome-icon>
    {{ $t('General.ExportExcel') }}</button>
</template>
  
<script setup lang="ts">
import ExcelJS from 'exceljs';
import { AccountView } from '@/models/generated/accountView.model';
import { formatDate } from '@/utils/formatBytes';

const props = defineProps<{ users: AccountView[] }>();

const generateExcel = () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Users');

  worksheet.columns = [
    { header: 'Nachname', key: 'lastName', width: 20 },
    { header: 'Vorname', key: 'firstName', width: 20 },
    { header: 'Email', key: 'email', width: 30 },
    { header: 'Projekte', key: 'countProjects', width: 15 },
    { header: 'Registriert am', key: 'dateCreated', width: 20 },
    { header: 'Account aktiviert', key: 'dateActivated', width: 20 },
    { header: 'Eingeloggt am', key: 'dateLastLogin', width: 20 },
    { header: 'Gelöscht am', key: 'dateDeleted', width: 20 },
  ];

  props.users.forEach(user => {
    worksheet.addRow(user);
  });

  // Generate and download the Excel file
  workbook.xlsx.writeBuffer().then(buffer => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    // get current date in format yyyyMMdd_HHmm
    const dateNow = formatDate(new Date(), 'yyyyMMdd_HHmm');
    link.setAttribute('download', dateNow + '_users.xlsx');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
</script>
  