import { ActionTree, Module, MutationTree } from 'vuex';
import axios from 'axios';
import { Upload } from 'tus-js-client';
import { formatBytes } from '@/utils/formatBytes';

export interface UploadState {
    isUploading: boolean;
    uploadProgress: number;
    uploadedSize: number;
    uploadedSizeUnit: string;
    fileSize: number;
    fileSizeUnit: string;
    uploadError: string | null;
    currentFile: File | null;
    uploadInstance: any; // Hold the tus upload instance,
    fileId: string;
}

const state: UploadState = {
    uploadProgress: 0,
    uploadedSize: 0,
    uploadedSizeUnit: 'MB',
    fileSize: 0,
    fileSizeUnit: 'MB',
    isUploading: false,
    uploadError: null,
    currentFile: null,
    uploadInstance: null,
    fileId: '',
};

const mutations: MutationTree<UploadState> = {
    setUploadProgress(state, { progress, bytesUploaded, bytesTotal }) {
        state.uploadProgress = progress;
        const uploadedFormat = formatBytes(bytesUploaded);
        const totalFormat = formatBytes(bytesTotal);
        state.uploadedSize = uploadedFormat.value;
        state.uploadedSizeUnit = uploadedFormat.unit;
        state.fileSize = totalFormat.value;
        state.fileSizeUnit = totalFormat.unit;
    },
    setUploadingState(state, { isUploading, file }) {
        state.isUploading = isUploading;
        // If we're starting an upload, set the file, otherwise clear it
        state.currentFile = isUploading ? file : null;
    },
    setUploadError(state, error) {
        state.uploadError = error;
    },
    setUploadInstance(state, uploadInstance) {
        state.uploadInstance = uploadInstance;
    },
    // setUploadDataUpdated(state, { data }) {
    //     console.log('Updated upload-data');
    // },
    setAssignUser(state) {
        // console.log('Assigned user');
        state.fileId = '';
    },
    setFileId(state, id) {
        state.fileId = id;
    },
};

const actions: ActionTree<UploadState, any> = {
    async updateUploadData(_, { data }) {
        // console.log('data', data);
        const response = await axios.put('/api/upload/' + data.id, data);
        // commit('setUploadDataUpdated', response.data);
        return response.data;
    },
    async assignUser({ commit }, { id }) {
        const response = await axios.post('/api/upload/' + id + '/assignUser');
        // .then(response => {
        commit('setAssignUser');
        // });
        return response.data;
    },
    async prepareUploadStart({ commit }) {
        const response = await axios.post('/api/upload/startupload');
        commit('setFileId', response.data);
        return response.data;
    },
    async startUpload({ commit, rootGetters }, { file, endpoint, id }) {
        // console.log('startupload', 'http://localhost:5212' + endpoint, state.fileId);
        const baseUrl = window.location.origin;
        endpoint = (baseUrl.includes(":8080") ? baseUrl.replace(':8080', ':5212') : '') + endpoint;
        commit('setUploadingState', { isUploading: true, file });

        const upload = new Upload(file, {
            endpoint: endpoint,
            retryDelays: [0, 1000, 3000, 5000],
            metadata: {
                filename: file.name,
                filetype: file.type,
                uploadId: id,
            },
            onProgress: (bytesUploaded: number, bytesTotal: number) => {
                const progress = +((bytesUploaded / bytesTotal) * 100).toFixed(2);
                commit('setUploadProgress', {
                    progress,
                    bytesUploaded,
                    bytesTotal,
                });
            },
            onSuccess: () => {
                commit('setUploadingState', { isUploading: false });
                if (rootGetters['auth/isAuthenticated']() === true) {
                    commit('setAssignUser');
                }

            },
            onError: (error: any) => {
                commit('setUploadError', error);
                commit('setUploadingState', { isUploading: false });
            },
        });

        commit('setUploadInstance', upload);
        try {
            const previousUploads = await upload.findPreviousUploads();
            console.log('previousUploads', previousUploads);
            // If previous uploads are found, resume the last one
            if (previousUploads.length) {
                upload.resumeFromPreviousUpload(previousUploads[0]);
            }
            upload.start();
            // router.push({ name: 'UploadData', params: { id: id } });
        } catch (error) {
            commit('setUploadError', error);
        }
    },
};

export const uploadsStore: Module<UploadState, any> = {
    namespaced: true,
    state,
    mutations,
    actions,
};
