import { Directive, DirectiveBinding, onBeforeUnmount } from 'vue';
import { debounce, validateInput } from './validationDirective';

export const formValidationDirective: Directive = {
  updated(formEl: HTMLFormElement, binding: DirectiveBinding<any>) {
    const locale = binding.value.locale;
      const inputs = formEl.querySelectorAll('input, select, textarea');
      inputs.forEach( (input: any) => {
        const options: any = {
          locale: locale ?? 'en'
        };
        if (input.type === 'email') {
          options.email = true;
        }
        if (input.type === 'password') {
          options.password = true;
        }
        if (input.type === 'checkbox') {
          options.checkbox = true;
        }
        
        validateInput(input, options);
      });
  },
  mounted(formEl: HTMLFormElement, binding: DirectiveBinding<any>) {
    let isFirstSubmit = true;
    const { submitMethod } = binding.value; // Destructure the passed submit method
    const locale = binding.value.locale;
    const handleSubmit = (event: SubmitEvent) => {
      event.preventDefault(); // Prevent default form submission

      const inputs = formEl.querySelectorAll('input, select, textarea');
      let isFormValid = true;
      inputs.forEach((input: any) => {
        const options: any = {
          locale: locale ?? 'en'
        };
        if (input.type === 'email') {
          options.email = true;
        }
        if (input.type === 'password') {
          options.password = true;
        }
        if (input.type === 'checkbox') {
          options.checkbox = true;
        }
        const handleInput: any = debounce(() => {
          validateInput(input, options);
        }, 50);
        
        if (isFirstSubmit) {
          input.addEventListener('input', handleInput);
          input.addEventListener('blur', handleInput);
          onBeforeUnmount(() => {
            input.removeEventListener('input', handleInput);
            input.removeEventListener('blur', handleInput);
          });
        }
        isFirstSubmit = false;
        
        if (!validateInput(input, options)) {
          isFormValid = false;
        }
      });

      if (isFormValid && submitMethod && typeof submitMethod === 'function') {
        submitMethod();
      }
    };

    formEl.onsubmit = handleSubmit;
  }
};

export const formValidationPlugin = {
  install(app: any) {
    app.directive('form-validate', formValidationDirective);
  },
};