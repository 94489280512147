import { ProcessingDurationView, StatisticsView, WaitingTimeView } from '@/models/statistics.model';
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import axios from 'axios';

export interface StatisticsState {
  statistics: StatisticsView[];
  processingTime: any;
  waitingTime: any[];
}

const state = (): StatisticsState => ({
  statistics: [],
  processingTime: {},
  waitingTime: [],
});

const mutations: MutationTree<StatisticsState> = {
  setStatistics: (state: StatisticsState, statistics: StatisticsView[]) => {
    state.statistics = statistics;
  },
  setProcessingTime: (state: StatisticsState, processingTime: ProcessingDurationView[]) => {
    state.processingTime = processingTime;
  },
  setWaitingTime: (state: StatisticsState, waitingTime: WaitingTimeView[]) => {
    state.waitingTime = waitingTime;
  },
};

const actions: ActionTree<StatisticsState, any> = {
  getUsage: async ({ commit }, { minDate, maxDate }: { minDate: Date, maxDate: Date }) => {
    const response = await axios.get<StatisticsView[]>('/api/statistics', {
      params: {
        minDate: minDate.toISOString(),
        maxDate: maxDate.toISOString()
      }
    });
    if (response.status >= 200 && response.status <= 299) {
      commit('setStatistics', response.data);
    }
  },
  getProcessingTime: async ({ commit }, { minDate, maxDate }: { minDate: Date, maxDate: Date }) => {
    const response = await axios.get<StatisticsView[]>('/api/statistics/totalProcessingTime', {
      params: {
        minDate: minDate.toISOString(),
        maxDate: maxDate.toISOString()
      }
    });
    if (response.status >= 200 && response.status <= 299) {
      commit('setProcessingTime', response.data);
    }
  },
  getWaitingTime: async ({ commit }, { minDate, maxDate }: { minDate: Date, maxDate: Date }) => {
    const response = await axios.get<StatisticsView[]>('/api/statistics/totalWaitingTime', {
      params: {
        minDate: minDate.toISOString(),
        maxDate: maxDate.toISOString()
      }
    });
    if (response.status >= 200 && response.status <= 299) {
      commit('setWaitingTime', response.data);
    }
  },
};

const getters: GetterTree<StatisticsState, any> = {
  statistics: (state: StatisticsState) => state.statistics,
  processingTime: (state: StatisticsState) => state.processingTime,
  waitingTime: (state: StatisticsState) => state.waitingTime,
};

export const statisticsStore = ({
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
});
