<template>
    <div v-if="showLoadingSpinner" class="level is-overlay">
        <div class="level-item has-text-centered">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from '../store';

const store = useStore();
const showLoadingSpinner = computed(() => store.state.app.showLoadingSpinner);
</script>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.is-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 2000;
}

</style>
