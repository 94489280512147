import { Toast } from "@/models";
import { MutationTree } from "vuex";

export interface AppState {
  toasts: Toast[],
  disableLoadingSpinner: boolean,
  showLoadingSpinner: boolean,
  showConfirmDialog: boolean,
  confirmDialogPayload: any, // { title / text / action() }
  acceptedDataPrivacy: boolean,
  acceptCookies: boolean,
  doNotShowCookies: boolean,
}

const mutations: MutationTree<AppState> = {
  showToast(state: AppState, payload: any) {
    state.toasts.push(payload);
  },
  dismissToast(state: AppState, index: number) {
    state.toasts.splice(index, 1);
  },
  showLoadingSpinner(state: AppState) {
    if (!state.disableLoadingSpinner) {
      state.showLoadingSpinner = true;
    }
  },
  hideLoadingSpinner(state: AppState) {
    state.showLoadingSpinner = false;
  }, 
  showConfirmDialog(state: AppState, payload: any) {
    state.showConfirmDialog = true;
    state.confirmDialogPayload = payload;
  },
  hideConfirmDialog(state: AppState) {
    state.showConfirmDialog = false;
    state.confirmDialogPayload = null;
  },
  acceptDataPrivacy(state: AppState) {
    state.acceptedDataPrivacy = true;
  },
  acceptCookies(state: AppState) {
    state.acceptCookies = true;
    state.doNotShowCookies = true;
  },
  declineCookies(state: AppState) {
    state.acceptCookies = false;
    state.doNotShowCookies = true;
  }
}

export const appStore = ({
  namespaced: true,
  state: {
    toasts: [] as Toast[],
    showLoadingSpinner: false,
    disableLoadingSpinner: false,
    showConfirmDialog: false
  },
  mutations
});