<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-2 has-text-centered">{{ $t('Faq.Title') }}</h1>
            <h2 class="subtitle is-3 has-text-centered">{{ $t('Faq.Subtitle') }}</h2>
            <div id="accordion">
                <div v-for="(item, index) in accordionItems" :key="index" class="box">
                    <header class="card-header" style="position:relative;" @click="toggleItem(index)">
                        <p class="card-header-title title is-5 mb-0">
                            {{ $t(item.title) }}
                        </p>
                        <font-awesome-icon style="position:absolute; right:8px; top: 8px;"
                            :icon="item.isOpen ? 'angle-up' : 'angle-down'" class="card-header-icon"></font-awesome-icon>
                    </header>
                    <transition name="accordion">
                        <div :class="{ hidden: !item.isOpen }" v-show="item.isOpen" class="card-content mt-2">
                            <div class="content">
                                {{ $t(item.content) }}
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const accordionItems = ref([
    { title: 'Faq.Questions.One.Question', content: 'Faq.Questions.One.Answer', isOpen: false },
    { title: 'Faq.Questions.Two.Question', content: 'Faq.Questions.Two.Answer', isOpen: false },
    { title: 'Question 3', content: 'Answer 3', isOpen: false },
    { title: 'Question 4', content: 'Answer 4', isOpen: false },
    { title: 'Question 5', content: 'Answer 5', isOpen: false },
    { title: 'Question 6', content: 'Answer 6', isOpen: false },
    // Add more items as needed
]);
const toggleItem = (index: number) => {
    accordionItems.value[index].isOpen = !accordionItems.value[index].isOpen;
};
</script>
<style>
.card-header {
    cursor: pointer;
}

.accordion-enter-active, .accordion-leave-active {
  transition: 0.35s ease-in-out;
  overflow: hidden;
}
.accordion-enter-from, .accordion-leave-to /* starting and ending state for leaving */ {
  transition: 0.35s ease-in-out;
  opacity: 0;
  max-height: 0;
}
.accordion-enter-to, .accordion-leave-from /* ending state for entering, starting state for leaving */ {
  transition: 0.35s ease-in-out;
  opacity: 1;
  max-height: 500px; /* adjust this value based on your content's size */
}
</style>