<template>
    <div class="box form-box">
        <h1 class="title is-1 has-text-centered pb-4">{{ $t('Login.Title') }}</h1>
        <h2 class="subtitle small is-5 has-text-centered">{{ $t('General.Or') }} <router-link
                to="/register">{{ $t('Register.Title') }}</router-link></h2>
        <div class="tab-content">
            <div class="login-form email mt-4" :class="{ 'is-active': activeTab === 'email' }">
                <form v-form-validate="{ submitMethod: submitEmailLogin, locale: locale }" name="login" novalidate>
                    <div class="field material-input">
                        <div class="control has-icons-left">
                            <input placeholder="" class="input" type="text" id="email" v-model="email" required />
                            <label class="label" for="email">{{ $t('General.Email') }}</label>
                            <!-- <span class="icon is-small is-left">
                                <font-awesome-icon icon="envelope" />
                            </span> -->
                        </div>
                    </div>
                    <div class="field material-input">
                        <div class="control has-icons-left">
                            <input placeholder="" class="input" type="password" id="password" v-model="password" required />
                            <label class="label" for="password">{{ $t('General.Password') }}
                            </label>
                            <!-- <span class="icon is-small is-left">
                                <font-awesome-icon icon="lock" />
                            </span> -->
                        </div>
                    </div>
                    <div class="field has-text-centered">
                        <button type="submit" class="button is-primary">{{ $t('Login.Submit') }}</button>
                    </div>
                    <div class="field has-text-centered">
                        <router-link to="/request-password-reset">{{ $t('Login.ForgotPassword') }}</router-link>
                        <template v-if="true || hasActivationLink">&nbsp;|
                            <router-link to="/resend-verify-registration">{{ $t('Login.SendActivationLink') }}</router-link>
                        </template>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '../store';
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const router = useRouter();
const store = useStore();
const activeTab = ref('email');
const email = ref('');
const password = ref('');
const hasActivationLink = ref<boolean>(false);

const submitEmailLogin = async () => {
    try {
        const result = await store.dispatch('auth/loginWithEmail', { email: email.value, password: password.value });
        if (result && result.token) {
            // console.log("email login pre");
            if (store.state.upload.fileId && store.state.upload.fileId !== '') {
                // console.log("email login drin", store.state.upload.fileId)
                await store.dispatch('upload/assignUser', { id: store.state.upload.fileId });
            }

            await router.push({ name: 'Projects' });
        } else if(result && result.response.response.data.errors) {
            if (result.response.response.data.errors && result.response.response.data.errors[0].includes("Aktivierungslink")) {
                hasActivationLink.value = true;
            } else {
                hasActivationLink.value = false;
            }
        }

    } catch (error) {
        // Fehlerbehandlung, z.B. Anzeige einer Fehlermeldung
        console.error('Login failed:', error);
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/variables.scss';

.tab-content {
    position: relative;
    overflow: hidden;
    height: 290px;
}

.login-form {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.tabs.is-toggle {
    li {
        min-width: 50%;

        a {
            transition: background-color 0.3s ease-in-out;
        }

        &.is-active {
            a {
                background-color: $tuerkis-02; // $green-02;
                border-color: $tuerkis-02; // $green-02;
            }
        }
    }
}

.login-form.email {
    transform: translateX(-102%);

    &.is-active {
        transform: translateX(0%);
    }
}

.login-form.ad {
    transform: translateX(102%);

    &.is-active {
        transform: translateX(0%);

        a {
            transition: background-color 0.3 ease-in-out;
        }
    }
}
</style>
