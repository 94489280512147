import { Directive, DirectiveBinding, onBeforeUnmount } from 'vue';

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPassword = (value: string) => (value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value) && /\W/.test(value));

export const validateInput = (inputElement: HTMLInputElement, options: any): boolean => {
  let isValid = true;
  let errorMessage = '';

  const locale = options.locale || 'en';

  if (inputElement.validity.valueMissing) {
    isValid = false;
    errorMessage = locale === 'de' ? 'Dieses Feld ist erforderlich' : 'This field is required';
  } else if (inputElement.validity.typeMismatch && inputElement.type === 'email') {
    isValid = false;
    errorMessage = locale === 'de' ? 'Bitte geben Sie eine gültige E-Mail-Adresse ein' : 'Please enter a valid email address';
  } else if (inputElement.validity.tooShort && inputElement.minLength !== undefined) {
    isValid = false;
    errorMessage = locale === 'de' ? `Die Mindestlänge beträgt ${inputElement.minLength} Zeichen` : `The minimum length is ${inputElement.minLength} characters`;
  } else if (inputElement.validity.tooLong && inputElement.maxLength !== undefined) {
    isValid = false;
    errorMessage = locale === 'de' ? `Die Maximallänge beträgt ${inputElement.maxLength} Zeichen` : `The maximum length is ${inputElement.maxLength} characters`;
  } else if (inputElement.validity.rangeUnderflow && inputElement.min === '0') {
    isValid = false;
    errorMessage = locale === 'de' ? 'Bitte eine positive Zahl eingeben' : 'Please enter a positive number';
  } else if (inputElement.validity.rangeUnderflow && inputElement.min !== '') {
    isValid = false;
    errorMessage = locale === 'de' ? `Bitte eine größere Zahl eingeben` : `Please enter a larger number`;
  } else if (inputElement.validity.rangeOverflow && inputElement.max !== '') {
    isValid = false;
    errorMessage = locale === 'de' ? `Bitte eine kleinere Zahl eingeben` : `Please enter a smaller number`;
  } else if (options.email && !validateEmail(inputElement.value)) {
    isValid = false;
    errorMessage = locale === 'de' ? 'Bitte geben Sie eine gültige E-Mail-Adresse ein' : 'Please enter a valid email address';
  } else if (options.password && !isValidPassword(inputElement.value)) {
    isValid = false;
    errorMessage = locale === 'de' ? 'Das Passwort muss mindestens 8 Zeichen lang sein, mindestens einen Groß- und Kleinbuchstaben sowie eine Zahl und ein Sonderzeichen enthalten.' : 'The password must be at least 8 characters long and contain at least one uppercase and lowercase letter, a number and a special character.';
  } else if (options.checkbox && inputElement.value.length <= 0) {
    isValid = false;
    errorMessage = locale === 'de' ? 'Zustimmung benötigt' : 'Consent required';
  }

  inputElement.setCustomValidity(isValid ? '' : errorMessage);
  updateErrorMessage(inputElement, errorMessage);

  if (!isValid) {
    inputElement.classList.add('is-danger'); // Apply the error border class
  } else {
    inputElement.classList.remove('is-danger'); // Remove the error border class
  }
  return isValid;
};


export const debounce = (func: any, delay: number): any => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]): void => {
    clearTimeout(timeoutId as ReturnType<typeof setTimeout>);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const updateErrorMessage = (inputElement: HTMLElement, errorMessage: string): void => {
  let errorMessageElement = inputElement.parentElement?.lastElementChild as HTMLElement;

  if (errorMessageElement && errorMessageElement.tagName === 'P') {
    errorMessageElement.textContent = errorMessage;

    if (errorMessage === '') {
      // Remove the error message when the input becomes valid
      errorMessageElement.remove();
    }
  } else {
    if (errorMessage !== '') {
      // Create a new error message element
      errorMessageElement = document.createElement('p');
      errorMessageElement.className = 'help is-danger';
      errorMessageElement.dataset.validationError = '';

      if (inputElement.getAttribute("type") !== "checkbox") {
        inputElement.parentElement?.appendChild(errorMessageElement);
        // } else {
        // const checkboxText = inputElement.nextElementSibling;
        // checkboxText?.prepend(errorMessageElement);
      }
      errorMessageElement.textContent = errorMessage;
    }
  }
};

// Use this only if you do not have a form wrapping the input: v-validate
export const validationDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding<any>) {
    const inputElement = el as HTMLInputElement;
    const options = binding.value || {};

    if (inputElement.type === 'email') {
      options.email = true;
    } else if (inputElement.type === 'password') {
      options.password = true;
    } else if (inputElement.type === 'checkbox') {
      options.checkbox = true;
    }

    const handleInput: any = debounce(() => {
      validateInput(inputElement, options);
    }, 50);

    inputElement.addEventListener('input', handleInput);
    inputElement.addEventListener('blur', handleInput);

    // Cleanup event listener on unmount
    onBeforeUnmount(() => {
      inputElement.removeEventListener('input', handleInput);
      inputElement.removeEventListener('blur', handleInput);
    });  
  },
};

export const validationPlugin = {
  install(app: any) {
    app.directive('validate', validationDirective);
  },
};
