import { ActionTree, GetterTree, MutationTree } from 'vuex';
import axios from 'axios';
import decode from "jwt-decode";
import { AccountView } from '@/models/generated/accountView.model';
// import bcrypt from 'bcrypt';

export interface AuthState {
  isAuthenticated: boolean,
  isAdmin: boolean,
  token?: string,
  roles: string[],
  expires?: Date,
  userData?: AccountView
}

function saveTokenToLocalStorage(token: string) {
  localStorage.setItem('nynecloud_accessToken', token);
}
function getTokenFromLocalStorage(): string | null {
  return localStorage.getItem('nynecloud_accessToken');
}

async function loginUser(credentials: any) {
  try {
    const response = await axios.post('/api/auth/login', credentials);
    return {success: response.status >= 200 && response.status < 300, response: response};
  } catch (error) {
    console.log("error", error);
    return {success: false, response: error as any};
  }
}

const mutations: MutationTree<AuthState> = {
  setAuthenticated(state: AuthState, authResponse: any) {
    if (!authResponse) {
      state.roles = [];
      state.token = undefined;
      state.expires = undefined;
      state.isAuthenticated = false;
      state.isAdmin = false;
    } else {
      state.roles = authResponse.roles;
      state.isAuthenticated = !!authResponse.token;
      state.isAdmin = authResponse.roles.includes("Administrator");
      state.token = authResponse.token;
      state.expires = authResponse.expirationDate;
      saveTokenToLocalStorage(authResponse.token);
    }
  },
  setUserData(state: AuthState, userData: AccountView) {
    state.userData = userData;
  },
  setUserDeletedDate(state: AuthState, date: Date) {
    if (state.userData) {
      state.userData.dateDeleted = date;
    }
  }
}

const actions: ActionTree<AuthState, any> = {
  async loginWithEmail({ commit }: { commit: any }, { email, password }: { email: string, password: string }) {
    // const saltRounds = 10;
    // const hashedPassword = await bcrypt.hash(password, saltRounds);
    // console.log("hashedPassword", hashedPassword);

    const authResponse = await loginUser({ email, password });
    // console.log("authresponse", authResponse);
    if (!authResponse.success || !authResponse || !authResponse.response.data || !authResponse.response.data.token) {
      return authResponse;
    }
    const tokenData: any = decode(authResponse.response.data.token);
    
    const expirationDate = new Date(tokenData.exp * 1000); // Convert the UNIX timestamp to a JavaScript Date object
    const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    commit('setAuthenticated', { roles: roles ?? [], token: authResponse.response.data.token, expires: expirationDate });
    return authResponse.response.data;
  },
  async register({ commit }: { commit: any }, { email, password, firstName, lastName }: { email: string, password: string, firstName: string, lastName: string }) {
    const response = await axios.post('/api/auth/register', { email, password, firstName, lastName });
    if (!response.data || !response.data.token) {
      return;
    }
    const tokenData: any = decode(response.data.token);
    
    const expirationDate = new Date(tokenData.exp * 1000); // Convert the UNIX timestamp to a JavaScript Date object
    const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    commit('setAuthenticated', { roles: roles ?? [], token: response.data.token, expires: expirationDate });
    return response.data.token;
  },
  async confirmRegistration(_, { email, token }: { email: string, token: string }) {
    await axios.post('/api/auth/verify-registration', { email, token });
  },
  async requestPasswordReset(_, { email }: { email: string }) {
    await axios.post('/api/auth/forgot-password', { email });
  },
  async resetPassword(_, { email, token, newPassword }: { email: string, token: string, newPassword: string }) {
    await axios.post('/api/auth/reset-password', { email, token, newPassword });
  },
  async resendVerifyRegistration(_, { email }: { email: string }) {
    await axios.post('/api/auth/resend-verify-registration', { email });
  },
  async getProfile({ commit }: { commit: any }) {
    const response = await axios.get('/api/auth/me');
    commit('setUserData', response.data);
  },
  async updateProfile({ commit }: { commit: any }, data) {
    const response = await axios.post('/api/auth/me', data);
    commit('setUserData', response.data);
  },
  async deleteProfile({ commit }: { commit: any }) {
    const response = await axios.delete('/api/auth/me');
    if (response.data) {
      commit('setUserDeletedDate', response.data);
    }
    return response.data;
  },
  async revertDeleteProfile({ commit }: { commit: any }) {
    const response = await axios.delete('/api/auth/me/revert');
    if (response.status >= 200 && response.status < 300) {
      commit('setUserDeletedDate', undefined);
    }
    return response.data;
  },
  logout({ commit }: { commit: any }) {
    localStorage.removeItem('nynecloud_accessToken');
    commit('setAuthenticated', undefined);
  },
}

const getters: GetterTree<AuthState, any> = {
  isAuthenticated: (state: AuthState) => () => {
    return state.isAuthenticated;
  },
};

const getAuthState = () => {
  const token = getTokenFromLocalStorage();

  if (token && token.length > 0) {
    const tokenData: any = decode(token);

    // Access the claims
    const expirationDate = new Date(tokenData.exp * 1000); // Convert the UNIX timestamp to a JavaScript Date object
    const roles = tokenData["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

    if (expirationDate > new Date()) {
      return { isAuthenticated: true, isAdmin: roles?.includes("Administrator"), roles, token: token, expires: expirationDate };
    }
  }

  return {
    isAuthenticated: false,
    isAdmin: false,
    token: "",
    roles: null,
    expired: null,
  }
}

export const authStore = ({
  namespaced: true,
  state: () => (getAuthState()),
  getters,
  mutations,
  actions,
});