import { createI18n, I18n, LocaleMessages } from 'vue-i18n';

const loadLocaleMessages = async (locale: string): Promise<LocaleMessages<string>> => {
    const messages = await import(`./locales/${locale}.json`);
    return messages.default;
  };

const setLocale = async (i18n: I18n, locale: string) => {
    const messages = await import(`./locales/${locale}.json`);
    i18n.global.setLocaleMessage(locale, messages.default);
}

let i18n: I18n;
    
const setupI18n = async() => {
  const defaultLocale = 'en';
  const messages = await loadLocaleMessages(defaultLocale);

  i18n = createI18n({
    legacy: false,
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages: {
      [defaultLocale]: messages
    }
  });

  return i18n;
}

export { i18n, setupI18n, setLocale, loadLocaleMessages };