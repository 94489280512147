import { Directive, DirectiveBinding } from 'vue';

export const loadImageDirective: Directive = {
  mounted(el: HTMLImageElement, binding: DirectiveBinding<any>) {
    const { projectId } = binding.value;
    const image = new Image();
    const imageUrl = `/api/project/${projectId}/preview`; //  "https://app.nyne.cloud/api/project/aee0c8cf-f857-409f-987d-c27b765461d8/preview";
    image.src = imageUrl;
    
    // const defaultSrc = el.src;
    image.onload = () => {
      el.src = image.src;
    };

    image.onerror = () => {
      el.src = "";
      el.parentNode?.removeChild(el);
    };
  },
};

export const loadImagePlugin = {
  install(app: any) {
    app.directive('loadimage', loadImageDirective);
  },
};
