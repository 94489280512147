<template>
    <section class="section">
        <div class="box form-box">
            <h1 class="title is-1 has-text-centered pb-4">{{ $t('RequestPasswordReset.Title') }}</h1>
            <h2 class="subtitle small is-5 has-text-centered">{{ $t('General.Or') }} <router-link to="/login">{{ $t('Login.Title') }}</router-link></h2>
            <div class="login-form email">
                <form v-form-validate="{ submitMethod: requestPasswordReset, locale: locale }" novalidate>
                    <div class="field material-input">
                        <div class="control has-icons-left">
                            <input placeholder="" class="input" type="email" id="email" v-model="email" required />
                            <label class="label" for="email">{{ $t('General.Email') }}</label>
                            <!-- <span class="icon is-small is-left">
                                <font-awesome-icon icon="envelope" />
                            </span> -->
                        </div>
                    </div>
                    <div class="field has-text-centered">
                        <button type="submit" class="button is-primary">{{ $t('RequestPasswordReset.Submit') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();
const router = useRouter();
const store = useStore();
const email = ref('');

async function requestPasswordReset() {
    try {
        await store.dispatch('auth/requestPasswordReset', {
            email: email.value,
        });
        store.commit('app/showToast', {
            message: t('RequestPasswordReset.Title') ,
            type: 'success',
        });
        router.push({ name: 'Login' });
        // Show success message or redirect to a success page
    } catch (error) {
        // Show error message
    }
}
</script>